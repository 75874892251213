import classNames from 'classnames';
import millify from 'millify';
import React, { memo, useEffect, useMemo, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import apiAgent from '../../../api/apiAgent';
import {
  Badge,
  ReactTable,
  StyledButton,
  WithLoadingDiv
} from '../../../components';
import DownloadAllEntries from './DownloadAllEntries';

import { useAtom } from 'jotai';
import { tableIsLoadingAtom } from '../../../atoms/dashboardStore';
import { ElementTextClassnames, IPaginationState } from '../../../interfaces';
import { TSearchFormParams } from '../../../interfaces/api';
import { dateFormatter, toastHandler } from '../../../utils';
import Pagination from './DeviceEventsTablePagination';
import GridChildTable from './GridChildTable';

import Select from 'react-select';

//Transforms the data for the table
const transformDataForTable = (list: any[], devices: any[]): any[] => {
  const devicesRef = new Map();
  devices.forEach((device) => {
    devicesRef.set(device.ifa, device.alias);
  });

  const countryRef = new Map();
  countryRef.set('SG', 'Singapore');
  countryRef.set('MY', 'Malaysia');
  countryRef.set('ID', 'Indonesia');

  const sourceRef = new Map();
  sourceRef.set(1, 'MW');
  sourceRef.set(2, 'MW_RT');
  sourceRef.set(3, 'MW_D0');
  sourceRef.set(4, 'MW_D1');
  sourceRef.set(5, 'MW_D2');
  sourceRef.set(6, 'MW_D3');
  sourceRef.set(7, 'MW_D4');
  sourceRef.set(8, 'MW_D5');
  sourceRef.set(9, 'ALM_RTB');
  sourceRef.set(10, 'ALM_SDK');
  sourceRef.set(11, 'QDT_EVAL');
  sourceRef.set(12, 'EI_EVAL');
  sourceRef.set(13, 'REDMOB_EVAL');
  sourceRef.set(14, 'BB_RTB');
  sourceRef.set(15, 'BB_S');

  const data = list.map((obj: any) => {
    return {
      id: obj.id,
      clusterTag: obj.cluster_tag,
      alias: devicesRef.get(obj.ifa),
      ifa: obj.ifa,
      startDatetime: dateFormatter({
        timestamp: obj.start_datetime,
        withTime: true
      }),
      latestDatetime: dateFormatter({
        timestamp: obj.latest_datetime,
        withTime: true
      }),
      createdDatetime: dateFormatter({
        timestamp: obj.created_at,
        withTime: true
      }),
      count: millify(parseInt(obj.count)),
      locationId: obj.ext_location_id,
      latitude: millify(obj.latitude, { precision: 4 }),
      longitude: millify(obj.longitude, { precision: 4 }),
      city: obj.city,
      state: obj.state,
      zipCode: parseInt(obj.zip_code) || 'Null',
      ip: obj.ip_address,
      countryId: obj.country_id,
      countryName: countryRef.get(obj.country_id),
      campaignId: obj.ext_campaign_id,
      asn: obj.asn,
      bundleId: obj.bundle_id,
      deviceManufacturer: obj.device_manufacturer,
      model: obj.model,
      deviceName: obj.devicename,
      platform: obj.platform,
      osVersion: obj.os_version,
      userAgent: obj.user_agent,
      carrier: obj.carrier,
      connectiontype: obj.connectiontype,
      sourceId: sourceRef.get(obj.source_id)
    };
  });

  return data || [];
};

//Columns for CSV download
const csvColumns = [
  { id: 'clusterTag', displayName: 'Cluster Tag' },
  { id: 'alias', displayName: 'Alias' },
  { id: 'ifa', displayName: 'IFA' },
  { id: 'startDatetime', displayName: 'Datetime Start' },
  { id: 'latestDatetime', displayName: 'Datetime End' },
  { id: 'createdDatetime', displayName: 'Uploaded At' },
  { id: 'count', displayName: 'Occurrences' },
  { id: 'locationId', displayName: 'Location ID' },
  { id: 'latitude', displayName: 'Lat' },
  { id: 'longitude', displayName: 'Lng' },
  { id: 'city', displayName: 'City' },
  { id: 'state', displayName: 'State' },
  { id: 'zipCode', displayName: 'Zip Code' },
  { id: 'ip', displayName: 'IP' },
  { id: 'countryId', displayName: 'Country ID' },
  { id: 'asn', displayName: 'Application' },
  { id: 'bundleId', displayName: 'Bundle ID' },
  { id: 'deviceManufacturer', displayName: 'Device Manufacturer' },
  { id: 'model', displayName: 'Model' },
  { id: 'deviceName', displayName: 'Device Name' },
  { id: 'platform', displayName: 'Platform' },
  { id: 'osVersion', displayName: 'OS' },
  { id: 'userAgent', displayName: 'User Agent' },
  { id: 'carrier', displayName: 'Carrier' },
  { id: 'connectiontype', displayName: 'Connection Type' },
  { id: 'sourceId', displayName: 'Source' }
];

/**
 * Renders a table of device events based on the provided search parameters.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {TSearchFormParams} [props.searchParams] - The search parameters for filtering the device events.
 * @param {any[]} [props.devicesList] - The list of devices.
 * @param {number} [props.totalEvents] - The total number of events.
 * @param {Function} props.setTotalEventsCount - A function to set the total events count.
 * @param {Function} props.setCoordinatesFromEventRecords - A function to set the coordinates from event records.
 * @returns {JSX.Element} The rendered DeviceEventsTable component.
 */
const DeviceEventsTable = ({
  searchParams,
  devicesList = [],
  totalEvents = 0,
  setTotalEventsCount,
  setCoordinatesFromEventRecords,
  setShowCSVModal,
  setJobDownloadId,
  jobDownloadId
}: {
  searchParams?: TSearchFormParams;
  devicesList?: any[];
  totalEvents?: number;
  setTotalEventsCount: Function;
  setCoordinatesFromEventRecords: Function;
  setShowCSVModal: (show: boolean) => void;
  setJobDownloadId: (jobId: string | null) => void;
  jobDownloadId: string | null;
}): JSX.Element => {
  const [tableIsLoading, setTableIsLoading] = useAtom(tableIsLoadingAtom);
  const [deviceEventsList, setDeviceEventsList] = useState<any[]>([]);
  const [displayedDeviceEventsList, setDisplayedDeviceEventsList] =
    useState<any>([]);

  const [paginationInfo, setPaginationInfo] = useState<IPaginationState>({
    totalItems: 0,
    currentPageCount: 0,
    itemsPerPage: 1000,
    totalPages: 0
  });

  //States to track the loading of the total records count
  const [isLoadingTotalCounts, setIsLoadingTotalCounts] =
    useState<boolean>(false);
  const [totalRecordsCount, setTotalRecordsCount] = useState<number>(0);

  //Following 4 states are used to track the multi date range queries
  const [totalAccumulatedPagesCount, setTotalAccumulatedPagesCount] =
    useState<number>(0); //Used to track current total loaded pages for multi date range queries
  //Used to track current loaded records for multi date range queries
  const [totalAccumulatedRecordsCount, setTotalAccumulatedRecordsCount] =
    useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1); //Track the current page to query for current date range stopped at for multi date range queries
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  //Track the multidate pages and date ranges. This will allow navigating across page be searching the right pages of the right date range.
  const [multiDateRangeArray, setMultiDateRangeArray] = useState<Date[][]>([]);
  const [currentMultiDateIndex, setCurrentMultiDateIndex] = useState<number>(0);

  const getTotalDeviceEventsCount = async () => {
    try {
      const { data } = await apiAgent.DeviceEvent.getEventsCount({
        ...searchParams,
        page: 1,
        limit: 1
      });

      setTotalEventsCount(data.meta.totalCount);
    } catch (error: any) {
      setTotalEventsCount(0);
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' },
        toastId: 'dashboard'
      });
    }
  };

  const fetchDeviceEventsListMultiDates = async (
    searchParams: TSearchFormParams | any,
    {
      page,
      limit
    }: {
      page: number;
      limit: number;
    },
    firstFetch: boolean = false
  ): Promise<void> => {
    try {
      setIsLoadingTotalCounts(true);

      //Declare variables to track the accumulated pages count, records count, and whether there are more pages to load
      let dateRangeArray: Date[][] = [];
      let totalPagesCount = totalAccumulatedPagesCount;
      let totalRecordsCount = totalAccumulatedRecordsCount;
      let tempHasNextPage = hasNextPage;
      let tempEventsData: any[] = [];
      let loopStartIndex = currentMultiDateIndex;
      let tempPage = page;

      //If this is the first fetch (Fresh filter submission), reset the accumulated page count and records count, and create the date range array
      if (firstFetch) {
        setTableIsLoading(true);
        // console.log(
        //   `First fetch, resetting accumulated page count and records count, and creating the date range array`
        // );
        setTotalAccumulatedPagesCount(1);
        setTotalAccumulatedRecordsCount(0);
        setCurrentPage(1);
        setHasNextPage(true);
        setCurrentMultiDateIndex(0);
        setDeviceEventsList([]);
        setPaginationInfo({
          ...paginationInfo,
          totalItems: 0,
          currentPageCount: 0,
          totalPages: 0
        });

        //Explicitly reset the variables declared above. Doing here because set states above may not immediately update the variables.
        totalPagesCount = 1;
        totalRecordsCount = 0;
        tempHasNextPage = true;
        loopStartIndex = 0;

        //If date range is given, check whether its more than N days. If so, break down in daily queries.
        const { startDate, endDate } = searchParams;

        let startDateCheck = new Date(startDate);
        const endDateCheck = new Date(endDate);
        const startDatePlusOne = new Date(startDate);
        startDatePlusOne.setDate(startDatePlusOne.getDate() + 1);
        startDatePlusOne.setHours(23, 59, 59, 999);

        // console.log(startDateCheck.toLocaleDateString());
        // console.log(endDateCheck.toLocaleDateString());
        // console.log(startDatePlusOne.toLocaleDateString());

        while (startDatePlusOne <= endDateCheck) {
          dateRangeArray.push([
            new Date(startDateCheck),
            new Date(startDatePlusOne)
          ]);

          startDateCheck = new Date(startDatePlusOne);
          startDateCheck.setDate(startDateCheck.getDate() + 1);
          startDateCheck.setHours(0, 0, 0, 0);
          startDatePlusOne.setDate(startDatePlusOne.getDate() + 1);
        }
        dateRangeArray.push([startDateCheck, endDateCheck]); //Add the final date range until endDate
        dateRangeArray.reverse(); //Reverse the array so latest date ranges get retrieved first
        setMultiDateRangeArray(dateRangeArray); //Set it to state for page navigation usage.
        // console.log(JSON.stringify(dateRangeArray, null, 2)); //Print out the date ranges array for info purpose
      } else {
        //If this is not the first fetch, use the date range array from state
        dateRangeArray = multiDateRangeArray;
      }

      // console.log(
      //   `Before for loop accumulated pages count is ${totalPagesCount}`
      // );
      // console.log(
      //   `Before for loop accumulated records count is ${totalRecordsCount}`
      // );

      //Loop through the date ranges, keep track of the date range index, and the current page index.
      for (let i = loopStartIndex; i < dateRangeArray.length; i++) {
        const range = dateRangeArray[i];

        // console.log(
        //   `\nFetching data for date range ${range[0].toLocaleDateString()} to ${range[1].toLocaleDateString()}`
        // );

        while (tempHasNextPage && tempEventsData.length < limit) {
          // console.log(`Fetching data for page ${tempPage}`);
          //Display data after accumulating enough records to hit page limit, of if there are no more data to load, or if we hit days where there is no data
          const isSearchMultiDateRange = i !== loopStartIndex ? true : false;

          const { data } = await apiAgent.DeviceEvent.getEventsList({
            ...searchParams,
            page: tempPage,
            limit,
            startDate: range[0],
            endDate: range[1],
            isSearchMultiDateRange, //To prevent continuous insertion of audit trail for the same filter search for each day queried.
            originalStartDate: searchParams.startDate
          });

          const pageInfo = data.meta;
          // console.log(`pageInfo is ${JSON.stringify(pageInfo, null, 2)} `);

          if (data.data.length > 0) {
            //Only accumulate data if there are records returned
            // console.log(
            //   `First 3 entries of data.data is ${JSON.stringify(
            //     data.data.slice(0, 3),
            //     null,
            //     2
            //   )}`
            // );

            tempEventsData = tempEventsData.concat(
              transformDataForTable(data.data, devicesList)
            );
            // console.log(
            //   `Current tempEventsData length is ${tempEventsData.length}`
            // );

            //If current accumulated data is more than limit, means we have accumulated enough records for one page.
            //This while loop will stop.
            // console.log(`\n\n\ntotalRecordsCount is ${totalRecordsCount}`);
            // console.log(`totalPagesCount is ${totalPagesCount}`);
            // console.log(`limit is ${limit}`);
            // console.log(
            //   `totalRecordsCount + data.data.length is ${
            //     totalRecordsCount + data.data.length
            //   }`
            // );
            // console.log(
            //   `totalPagesCount * limit is ${totalPagesCount * limit}`
            // );
            if (
              totalRecordsCount + data.data.length >
              totalPagesCount * limit
            ) {
              totalPagesCount++;
              // console.log(
              //   `Current accumulated pages count is ${totalPagesCount}`
              // );
            }

            totalRecordsCount += pageInfo.currentPageCount;
            // console.log(
            //   `Current accumulated records count is ${totalRecordsCount}`
            // );
            setTotalAccumulatedRecordsCount(totalRecordsCount);
          }

          //If current retrieved data is lesser than limit, means this is the final page for this date range
          if (pageInfo.currentPageCount < limit) {
            tempHasNextPage = false; //Set to false to stop the while loop

            //If this is also the final date range, set no more next page to prevent further queries
            if (i === dateRangeArray.length - 1) {
              setHasNextPage(false); //Set to false to stop the for loop
            }

            //this is not the final date range, reset the page to 1 for next date range query
            else {
              tempPage = 1; //Reset page to 1 for next date range query in the for loop
              setCurrentMultiDateIndex(i + 1); //Set the next date range index to be queried
            }
          }

          //If current retrieved data is equal to limit, means there are more pages to query for this date range
          else {
            tempPage++;
          }
        } //End of while loop

        //If we have accumulated enough records for one page break the for loop.
        if (tempEventsData.length >= limit) {
          break;
        }

        tempHasNextPage = true; //Reset for next date range query
      } //End of date range for loop

      //Only reaches here if we have accumulated enough records for one page or if we have looped through all date ranges data
      // console.log(
      //   `\nStopped at date range index ${currentMultiDateIndex} which is ${dateRangeArray[
      //     currentMultiDateIndex
      //   ][0].toLocaleDateString()} to ${dateRangeArray[
      //     currentMultiDateIndex
      //   ][1].toLocaleDateString()}`
      // );
      // console.log(`Stopped querying at page ${tempPage}`);
      setCurrentPage(tempPage);

      if (firstFetch) {
        //This will ensure if its new fetch, deviceEventsList will always reset
        setDeviceEventsList(tempEventsData); //Set the accumulated records to state

        //Only display newly accumulated records if its the first fetch. Subsequent fetch will accumulate records, but won't be shown unless use click on page.
        setDisplayedDeviceEventsList(tempEventsData); //Display the accumulated records
      } else {
        setDeviceEventsList(deviceEventsList.concat(tempEventsData)); //Set the accumulated records to state
        setDisplayedDeviceEventsList(deviceEventsList.splice(0, limit)); //Display the first page of records to match pagination component when fetching more data
      }
      setTotalEventsCount(totalRecordsCount);

      // console.log(`Total accumulated pages count is ${totalPagesCount}`);
      setTotalAccumulatedPagesCount(totalPagesCount);
      // console.log(`Total accumulated records count is ${totalRecordsCount}`);
      setTotalAccumulatedRecordsCount(totalRecordsCount);

      let lastCurrentPageCount = limit;

      if (totalPagesCount === 1) {
        lastCurrentPageCount =
          tempEventsData.length - limit * (totalPagesCount - 1);
      }

      setPaginationInfo({
        ...paginationInfo,
        currentPageCount: lastCurrentPageCount,
        totalItems: totalRecordsCount,
        totalPages: totalPagesCount
      });
    } catch (error: any) {
      setDeviceEventsList([]);
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' },
        toastId: 'dashboard'
      });
    } finally {
      setTableIsLoading(false);
      setIsLoadingTotalCounts(false);
    }
  };

  const fetchMoreData = async () => {
    const limit = paginationInfo.itemsPerPage;
    await fetchDeviceEventsListMultiDates(searchParams, {
      page: currentPage,
      limit
    });
  };

  const loadDataFromMemory = ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }) => {
    try {
      setTableIsLoading(true);

      const offset = page * limit;
      // console.log(`Clicked page is ${page}`);
      // console.log(`Grabbing data from ${offset - limit} to ${offset}`);

      // console.log(`deviceEventsList length is ${deviceEventsList.length}`);

      const dataSubset = deviceEventsList.slice(offset - limit, offset);

      // console.log(
      //   `dataSubset is ${JSON.stringify(
      //     dataSubset.slice(0, 3).map(({ id, ifa, latestDatetime }) => ({
      //       id,
      //       ifa,
      //       latestDatetime
      //     })),
      //     null,
      //     2
      //   )}`
      // );
      // console.log(`dataSubset length is ${dataSubset.length}`);

      setPaginationInfo({
        ...paginationInfo,
        currentPageCount: dataSubset.length
      });

      setDisplayedDeviceEventsList(dataSubset);
    } catch (error: any) {
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' },
        toastId: 'dashboard'
      });
    } finally {
      setTableIsLoading(false);
    }
  };

  //Fetches and handles page change for filtering of single day events
  const fetchDeviceEventsList = async ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }): Promise<void> => {
    try {
      setTableIsLoading(true);

      const { data } = await apiAgent.DeviceEvent.getEventsList({
        ...searchParams,
        page,
        limit
      });

      // console.log(`getEventList result is ${JSON.stringify(data, null, 2)}`);

      const pageInfo = data.meta;

      const transformedData = transformDataForTable(data.data, devicesList);
      setDisplayedDeviceEventsList(transformedData);
      setDeviceEventsList(transformedData);

      setTotalEventsCount(pageInfo.totalCount);

      setPaginationInfo({
        ...paginationInfo,
        totalItems: pageInfo.totalCount,
        currentPageCount: pageInfo.currentPageCount,
        totalPages: pageInfo.totalPages
      });
    } catch (error: any) {
      setDeviceEventsList([]);
      toastHandler({
        messages: error?.message,
        toastOptions: { type: 'error' },
        toastId: 'dashboard'
      });
    } finally {
      setTableIsLoading(false);
    }
  };

  const onChangeItemsPerPageLimit = (event: any) => {
    const updatedPaginationInfo = {
      ...paginationInfo,
      itemsPerPage: event.value
    };
    // console.log(JSON.stringify(updatedPaginationInfo, null, 2));

    setPaginationInfo(updatedPaginationInfo);
  };

  // const loadMoreData = async () => {
  //   // console.log(`loadMoreData called`);

  //   if (searchParams && searchParams.startDate && searchParams.endDate) {
  //     console.log(`calling fetchDeviceEventsListMultiDates`);
  //     fetchDeviceEventsListMultiDates(searchParams, {
  //       page: currentPage,
  //       limit: paginationInfo.itemsPerPage
  //     });
  //   } else {
  //     // console.log(`calling fetchDeviceEventsList`);
  //     fetchDeviceEventsList({
  //       page: currentPage,
  //       limit: paginationInfo.itemsPerPage
  //     });
  //   }
  // };

  const checkDateDiff = (
    startDate: string | number | Date,
    endDate: string | number | Date
  ): number => {
    const startDateCheck: Date = new Date(startDate);
    const endDateCheck: Date = new Date(endDate);

    const diffInMs = Math.abs(
      endDateCheck.getTime() - startDateCheck.getTime()
    );
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    // To display the final no. of days (result)
    // console.log(
    //   'Total number of days between dates:\n' +
    //     startDate.toLocaleString() +
    //     '\n and \n' +
    //     endDate.toLocaleString() +
    //     ' is: \n' +
    //     diffInDays +
    //     ' days'
    // );

    return diffInDays;
  };

  const checkDateAndFetchData = async ({
    page,
    limit
  }: {
    page: number;
    limit: number;
  }) => {
    if (searchParams && searchParams.startDate && searchParams.endDate) {
      if (checkDateDiff(searchParams.startDate, searchParams.endDate) > 1) {
        // console.log(`calling fetchDeviceEventsListMultiDates`);
        // fetchDeviceEventsListMultiDates(searchParams, {
        //   page,
        //   limit
        // });
        // console.log(`\ncalling loadDataFromMemory`);
        loadDataFromMemory({
          page,
          limit
        });
      } else {
        // console.log(`\ncalling fetchDeviceEventsList`);
        setDisplayedDeviceEventsList(deviceEventsList.slice(0, limit)); //Display the first page of records when fetching more data
        fetchDeviceEventsList({
          page,
          limit
        });
      }
    } else {
      // console.log(`\ncalling fetchDeviceEventsList`);
      setDisplayedDeviceEventsList(deviceEventsList.slice(0, limit)); //Display the first page of records when fetching more data
      fetchDeviceEventsList({
        page,
        limit
      });
    }
  };

  useEffect(() => {
    setMultiDateRangeArray([]); //Clear multiDateRangeArray when searchParams changes

    if (searchParams && searchParams.startDate && searchParams.endDate) {
      //if startDate and endDate has more than 2 days, fetchMapArrayMultiDates

      if (checkDateDiff(searchParams.startDate, searchParams.endDate) > 1) {
        // console.log(`calling fetchDeviceEventsListMultiDates`);
        fetchDeviceEventsListMultiDates(
          searchParams,
          {
            page: 1,
            limit: paginationInfo.itemsPerPage
          },
          true //This true boolean will trigger the first fetch to reset the accumulated page count and records count, and create the date range array
        );
      } else {
        // console.log(`calling fetchDeviceEventsList`);
        fetchDeviceEventsList({
          page: 1,
          limit: paginationInfo.itemsPerPage
        });
      }
    } else {
      fetchDeviceEventsList({
        page: 1,
        limit: paginationInfo.itemsPerPage
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, paginationInfo.itemsPerPage]);

  useEffect(() => {
    // console.log(`\npaginationInfo.totalItems updated`);
    // console.log(`paginationInfo is ${JSON.stringify(paginationInfo, null, 2)}`);
  }, [paginationInfo.totalItems]);

  useEffect(() => {
    // console.log(`\ndeviceEventsList updated`);
    // console.log(`deviceEventsList length is ${deviceEventsList.length}`);
  }, [deviceEventsList]);

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'GoToLocation',
        thClassName: 'px-2',
        tdClassName: 'whitespace-nowrap',
        Cell: ({ row }: any) => {
          return (
            <StyledButton className="pt-2">
              <i
                className="fa-solid fa-location-dot"
                onClick={() => {
                  // alert(JSON.stringify(row.values));
                  setCoordinatesFromEventRecords([
                    row.values.latitude,
                    row.values.longitude
                  ]);
                }}
              ></i>
            </StyledButton>
          );
        }
      },
      {
        Header: 'Cluster Tag',
        accessor: 'clusterTag',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        ),
        tdClassName: 'whitespace-nowrap',
        Cell: ({ row }: any) => {
          return <Badge>{row.values.clusterTag}</Badge>;
        }
      },
      {
        Header: 'Alias',
        accessor: 'alias',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        ),
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'ifa',
        accessor: 'ifa',
        thClassName: classNames(
          ElementTextClassnames.sysGenId,
          'whitespace-nowrap',
          'px-2'
        ),
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Datetime Start',
        accessor: 'startDatetime',
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        ),
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Datetime End',
        accessor: 'latestDatetime', // accessor is the "key" in the data
        thClassName: classNames(
          ElementTextClassnames.sysGenDate,
          'whitespace-nowrap px-2'
        ),
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Uploaded At',
        accessor: 'createdDatetime',
        thClassName: 'px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Occurrences',
        accessor: 'count',
        thClassName: 'px-2'
      },
      {
        Header: 'Location ID',
        accessor: 'locationId',
        thClassName: classNames(
          ElementTextClassnames.sysGenIdAlt,
          'whitespace-nowrap px-2'
        ),
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Lat',
        accessor: 'latitude',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Lng',
        accessor: 'longitude',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'City',
        accessor: 'city',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'State',
        accessor: 'state',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Zip code',
        accessor: 'zipCode',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'IP',
        accessor: 'ip',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Country ID',
        accessor: 'countryId',
        thClassName: classNames(
          ElementTextClassnames.sysGenIdAlt,
          'whitespace-nowrap px-2'
        ),
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Country Name',
        accessor: 'countryName',
        thClassName: classNames(
          ElementTextClassnames.sysGenIdAlt,
          'whitespace-nowrap px-2'
        ),
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Campaign ID',
        accessor: 'campaignId',
        thClassName: classNames(
          ElementTextClassnames.sysGenIdAlt,
          'whitespace-nowrap px-2'
        ),
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Application',
        accessor: 'asn',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Bundle ID',
        accessor: 'bundleId',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Device Manufacturer',
        accessor: 'deviceManufacturer',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Model',
        accessor: 'model',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Device Name',
        accessor: 'deviceName',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Platform',
        accessor: 'platform',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'OS',
        accessor: 'osVersion',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'User Agent',
        accessor: 'userAgent',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Carrier',
        accessor: 'carrier',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Connection Type',
        accessor: 'connectiontype',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      },
      {
        Header: 'Source',
        accessor: 'sourceId',
        thClassName: 'whitespace-nowrap px-2',
        tdClassName: 'whitespace-nowrap'
      }
    ],
    [JSON.stringify(deviceEventsList)]
  );

  return (
    <GridChildTable className="col-span-6" title="Events Record">
      {/* {(searchText || '').length + (searchClusterTags || []).length > 0 ? (
        <Badge
          className={`mb-2 mx-auto table ${ElementTextClassnames.warning}`}
        >
          Searching Events based on
          {(searchText || '').length > 0 ? ` "${searchText}" ` : ''}
          {(searchText || '').length > 0 &&
            (searchClusterTags || []).length > 0 &&
            ' and '}
          {(searchClusterTags || []).length > 0
            ? ` ${(searchClusterTags || []).join(', ')}`
            : ''}
        </Badge>
      ) : (
        ''
      )} */}

      <WithLoadingDiv isLoading={tableIsLoading}>
        <div
          className="-mx-5 mb-5 overflow-y-auto overflow-x-auto"
          style={{ maxHeight: '80vh' }}
        >
          <ReactTable
            columns={columns}
            data={displayedDeviceEventsList}
            className="w-full"
          />
        </div>
      </WithLoadingDiv>

      <div>
        {deviceEventsList.length > 0 && (
          <React.Fragment>
            {isLoadingTotalCounts ? (
              <div>
                <i className={`fas fa-circle-notch fa-spin text-white`} />{' '}
                Loading more records... Currently found{' '}
                {totalAccumulatedRecordsCount} records...
              </div>
            ) : (
              <React.Fragment>
                {multiDateRangeArray.length > 0 && (
                  <div>
                    Loaded {totalAccumulatedRecordsCount} records. {` `}
                    {hasNextPage && (
                      <StyledButton onClick={fetchMoreData}>
                        Load more
                      </StyledButton>
                    )}
                  </div>
                )}

                <Pagination
                  totalPages={paginationInfo.totalPages}
                  totalItems={paginationInfo.totalItems}
                  currentPageCount={paginationInfo.currentPageCount}
                  itemsPerPage={paginationInfo.itemsPerPage}
                  fetchData={checkDateAndFetchData}
                />

                <div className="flex justify-end items-center mt-2">
                  <label className="mr-2">Entries Per Page: </label>
                  <Select
                    name="itemsPerPageLimit"
                    menuPlacement="top"
                    styles={{
                      input: (provided: any) => ({
                        ...provided,
                        color: '#FFFFFF'
                      })
                    }}
                    className={'select-special-container w-32 mr-2'}
                    classNamePrefix="tw-select"
                    defaultValue={{
                      label: `${paginationInfo.itemsPerPage}`,
                      value: paginationInfo.itemsPerPage
                    }}
                    isSearchable={false}
                    options={[
                      { label: '10', value: 10 },
                      { label: '25', value: 25 },
                      { label: '50', value: 50 },
                      { label: '100', value: 100 },
                      { label: '500', value: 500 },
                      { label: '1000', value: 1000 },
                      { label: '10000', value: 10000 }
                    ]}
                    onChange={onChangeItemsPerPageLimit}
                  />
                  <CsvDownloader
                    datas={displayedDeviceEventsList}
                    columns={csvColumns}
                    wrapColumnChar={`"`}
                    filename={`device_events_list_${paginationInfo.itemsPerPage}_rows`}
                  >
                    <StyledButton>Download CSV</StyledButton>
                  </CsvDownloader>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>

      <div>
        {deviceEventsList.length > 0 && (
          <DownloadAllEntries
            setJobDownloadId={setJobDownloadId}
            jobDownloadId={jobDownloadId}
            setShowCSVModal={setShowCSVModal}
            searchParams={searchParams}
            csvColumns={csvColumns}
            totalEventsCount={paginationInfo.totalItems}
          />
        )}
      </div>
    </GridChildTable>
  );
};

export default memo(DeviceEventsTable);
