import React, { useCallback, useEffect, useRef, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import apiAgent from '../../../api/apiAgent';
import { SmallLoader, StyledButton } from '../../../components';
import { toastHandler } from '../../../utils';

interface DownloadAllEntriesProps {
  searchParams: any;
  csvColumns: any;
  setShowCSVModal: (show: boolean) => void;
  setJobDownloadId: (jobId: string | null) => void;
  jobDownloadId: string | null;
  totalEventsCount: number;
}

const DownloadAllEntries: React.FC<DownloadAllEntriesProps> = ({
  searchParams,
  csvColumns,
  setShowCSVModal,
  setJobDownloadId,
  jobDownloadId,
  totalEventsCount
}) => {
  const [downloadState, setDownloadState] = useState({
    isDownloading: false,
    progress: 0,
    error: null as string | null,
    csvData: null as any[] | null,
    fileName: '',
    downloadStats: null as any
  });
  const [showSmallLoader, setShowSmallLoader] = useState(false);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const resetDownload = useCallback(() => {
    console.log('Resetting download state');
    setDownloadState({
      isDownloading: false,
      progress: 0,
      error: null,
      csvData: null,
      fileName: '',
      downloadStats: null
    });
    setJobDownloadId(null);
    setShowCSVModal(false);
    setShowSmallLoader(false);
  }, [setJobDownloadId, setShowCSVModal]);

  const startDownload = async () => {
    try {
      console.log('Starting download with search params:', searchParams);
      console.log(`Total events from getEventsList: ${totalEventsCount}`);
      setShowSmallLoader(true);
      setDownloadState((prev) => ({
        ...prev,
        isDownloading: true,
        error: null,
        csvData: null
      }));
      const response = await apiAgent.DeviceEvent.startAllEventsListDownload(
        searchParams
      );
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      const newJobId = response.data.data.jobId;
      console.log(`New download job started with ID: ${newJobId}`);
      setShowCSVModal(true);
      setShowSmallLoader(false);
      setJobDownloadId(newJobId);
      checkProgress(newJobId);
    } catch (err: any) {
      console.error('Error starting download:', err);
      const errorMessage =
        err?.response?.data?.error ||
        'Failed to start download due to server error';
      toastHandler({ messages: errorMessage, toastOptions: { type: 'error' } });
      resetDownload();
    }
  };

  const checkProgress = useCallback(
    async (id: string) => {
      try {
        console.log(`Checking progress for job ID: ${id}`);
        const response = await apiAgent.DeviceEvent.getAllEventsListProgress(
          id
        );
        const {
          status,
          progress,
          totalRecords,
          processedRecords,
          estimatedSize
        } = response.data.data;

        console.log(`Job ${id} status: ${status}, progress: ${progress}%`);
        console.log(
          `Processed: ${processedRecords}/${totalRecords}, Estimated size: ${estimatedSize}`
        );

        setDownloadState((prev) => ({
          ...prev,
          progress,
          downloadStats: { totalRecords, processedRecords, estimatedSize }
        }));

        if (status === 'completed') {
          console.log('Download completed, fetching CSV data');
          const csvResponse = await apiAgent.DeviceEvent.getCSVData(id);
          console.log(
            `Received CSV data with ${csvResponse.data.data.length} rows`
          );
          setDownloadState((prev) => ({
            ...prev,
            isDownloading: false,
            csvData: csvResponse.data.data,
            fileName: csvResponse.data.filename
          }));
        } else if (status === 'cancelled') {
          console.log(`Job ${id} was cancelled`);
          resetDownload();
        } else if (status === 'failed') {
          throw new Error('Job failed on the server');
        }
      } catch (err: any) {
        console.error(`Error checking progress for job ${id}:`, err);
        if (err.response && err.response.status === 502) {
          toastHandler({
            messages:
              'Server is temporarily unavailable. Retrying in 5 seconds...',
            toastOptions: { type: 'warning' }
          });
          setTimeout(() => checkProgress(id), 5000);
        } else {
          toastHandler({
            messages: `Error checking download progress: ${err.message}. Please try again.`,
            toastOptions: { type: 'error' }
          });
          resetDownload();
        }
      }
    },
    [resetDownload]
  );

  const cancelJob = useCallback(async () => {
    if (jobDownloadId) {
      try {
        console.log(`Cancelling job ${jobDownloadId}`);
        await apiAgent.DeviceEvent.cancelAllEventsListDownload(jobDownloadId);
        resetDownload();
      } catch (err) {
        console.error(`Error cancelling job ${jobDownloadId}:`, err);
      }
    }
  }, [jobDownloadId, resetDownload]);

  useEffect(() => {
    if (jobDownloadId && downloadState.isDownloading) {
      console.log(
        `Setting up progress check interval for job ${jobDownloadId}`
      );
      checkProgress(jobDownloadId);
      intervalIdRef.current = setInterval(
        () => checkProgress(jobDownloadId),
        2000
      );
    }
    return () => {
      if (intervalIdRef.current) {
        console.log(
          `Clearing progress check interval for job ${jobDownloadId}`
        );
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [jobDownloadId, downloadState.isDownloading, checkProgress]);

  useEffect(() => {
    console.log('Search params changed, resetting download');
    resetDownload();
  }, [searchParams, resetDownload]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (jobDownloadId && downloadState.isDownloading) {
        console.log('Page is being unloaded, cancelling job');
        cancelJob();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [jobDownloadId, downloadState.isDownloading, cancelJob]);

  const { isDownloading, progress, error, csvData, fileName, downloadStats } =
    downloadState;

  !!isDownloading && console.log('Current download state:', downloadState);

  return (
    <div className="flex flex-col items-end mt-2">
      {error && <div className="text-red-500 mb-2">{error}</div>}
      {isDownloading && !showSmallLoader ? (
        <div className="flex flex-col items-end">
          <div className="mb-2">Preparing CSV download: {progress}%</div>
          <div className="w-[210px] h-2 bg-gray-200 rounded-full mb-2">
            <div
              className="h-full bg-blue-500 rounded-full"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          {downloadStats && (
            <div className="text-sm text-gray-600 mb-2">
              <div>
                Processed: {downloadStats.processedRecords.toLocaleString()} /{' '}
                {downloadStats.totalRecords.toLocaleString()} records
              </div>
              <div>Estimated size: {downloadStats.estimatedSize}</div>
            </div>
          )}
          <StyledButton onClick={cancelJob}>Cancel Download</StyledButton>
        </div>
      ) : csvData ? (
        <CsvDownloader
          datas={csvData}
          columns={csvColumns}
          filename={fileName}
          wrapColumnChar={`"`}
        >
          <StyledButton>Download All Entries CSV</StyledButton>
        </CsvDownloader>
      ) : (
        <StyledButton onClick={startDownload} disabled={isDownloading}>
          <i className="fa fa-download mr-2" />

          {!!showSmallLoader ? (
            <>
              Preparing Entries ... <SmallLoader className="ml-1" />
            </>
          ) : (
            'Prepare All Entries CSV'
          )}
        </StyledButton>
      )}
    </div>
  );
};

export default DownloadAllEntries;
